import { MDXProvider } from "@mdx-js/react"
import { graphql, useStaticQuery } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import React, { useState } from 'react'
import './ClinicFinder.scss'

const ClinicFinder = ({setCloseModal, modal=false}) => {

  const [zipcode, setZipcode] = useState("")

  const handleSubmit = (event) => {
    //window.open(`https://www.bedsider.org/clinics?location=${zipcode}`, "_blank",'noopener,noreferrer')
    window.open(`https://www.bedsider.org/find-health-care/clinics?location=${zipcode}`, "_blank",'noopener,noreferrer')
    event.preventDefault();
  }

  const data = useStaticQuery( graphql`
    query {
      mdx(slug: {eq: "ankle/find-clinic"}) { 
          body
      }
    }
  `)

  return (
    <div id='ClinicFinder'>
      {modal && 
        <div className="header">
            <img src="/assets/logo.svg" alt="logo"/>
            <button onClick={setCloseModal} className="close"><span>X</span></button>
        </div>
      }
      <MDXProvider components={{}}>
        <MDXRenderer>
          {data.mdx.body}
        </MDXRenderer>
      </MDXProvider>

      <div className='FindClinic'>
        <form onSubmit={handleSubmit}>
          <input type="text" placeholder="zip code" value={zipcode} onChange={(event) => setZipcode(event.target.value)} />
          <button className="ankle-button">SEARCH</button>
        </form>
        <div className="link-label">
            <span>Powered by</span><a href="https://bedsider.org" target="blank">Bedsider.org</a>
        </div>
      </div>
    </div>
  )
}

export default ClinicFinder