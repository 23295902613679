import { graphql, useStaticQuery } from "gatsby";
import React, { useState } from 'react';
import DesktopMenuPanel from './DesktopMenuPanel';


const buildLinkMap = (data) => {
  const nodes = data.allSitePage.nodes;
  nodes.sort((a,b) => (a.path > b.path) ? 1 : ((b.path > a.path) ? -1 : 0))

  const sections = nodes.filter(node => node.context.section).map(node => {
      return {
        path: "/" + node.context.section.slug,
        order: node.context.section.frontmatter.order,
        title: node.context.section.frontmatter.sectionTitle,
        children: []
      }
  });

  const tmpTopics = nodes.filter(node => node.context.topic).map(node => {
      return {
        path: "/" + node.context.topic.slug,
        title: node.context.topic.frontmatter.topicTitle,
        order: node.context.topic.frontmatter.order,
        children: []
      }
  });

  // Manually remove the Topic pages that should only be visible from the clinic tour
  const topics = tmpTopics.filter(page => !page.path.startsWith("/explore-a-clinic"));

  const details = nodes.filter(node => node.context.detail).map(node => {
      return {
        path: "/" + node.context.detail.slug,
        title: node.context.detail.frontmatter.detailTitle,
        order: node.context.detail.frontmatter.order,
      }
  });

  sections.sort((a,b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0))
  topics.sort((a,b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0))
  details.sort((a,b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0))

  sections.forEach(section => {
    topics.forEach(topic => {
      if (topic.path.startsWith(section.path)) {
        section.children.push(topic)
      }
    })
  })


  topics.forEach(topic => {
    details.forEach(detail => {
      const partsCount = (detail.path.match(/\//g) || []).length;
      // Skip the subdetail pages
      if (detail.path.startsWith(topic.path) && partsCount === 4 ) {
        topic.children.push(detail)
      }
    })
  })

  return sections;
}

export default function DesktopMenu({closeMenuHandler}) {

  const [showTopicMenu, setShowTopicMenu] = useState(false);
  const [sectionIdx, setSectionIdx] = useState(null);
  const [showDetailMenu, setShowDetailMenu] = useState(false);
  const [topicIdx, setTopicIdx] = useState(null);

  const data = useStaticQuery( graphql`
    query {
      allSitePage {
        nodes {
          path
          context {
            section {
              slug
              frontmatter {
                sectionTitle
                order
              }
            }
            detail {
              slug
              frontmatter {
                detailTitle
                order
              }
            }
            topic {
              slug
              frontmatter {
                topicTitle
                order
              }
            }
          }
        }
      }
    }`
  )

  const sections = buildLinkMap(data);

  function handleSectionHover(idx) {
    setSectionIdx(idx)
    setShowTopicMenu(true)
    setTopicIdx(null)
    setShowDetailMenu(false)
  }

  function handleTopicHover(idx) {
    setTopicIdx(idx)
    setShowDetailMenu(true)
  }

  return (
    <div 
      onMouseLeave={closeMenuHandler} 
      role="button" 
      tabIndex="0" 
      className='DesktopMenu'
    >
      <DesktopMenuPanel linkMap={sections} panelType="section" handleHover={handleSectionHover} linkIdx={sectionIdx} />
      {showTopicMenu &&
        <DesktopMenuPanel linkMap={sections[sectionIdx].children} panelType="topic" handleHover={handleTopicHover} linkIdx={topicIdx} />
      }
      {showDetailMenu && sections[sectionIdx].children[topicIdx].children.length > 0 &&
        <DesktopMenuPanel linkMap={sections[sectionIdx].children[topicIdx].children} panelType="detail"/>
      }
    </div>
  )
}
