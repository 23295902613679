import { Link } from 'gatsby';
import React, { useState } from 'react';
import './BurgerExpandableLink.scss';

const BurgerExpandableLink = ({display, to, children}) => {
  const [contentOpen, setContentOpen] = useState(false);
  return (
    <div className='BurgerExpandableLink'>
      <div className="expandable">
        <Link className="bm-item" to={to}>{display}</Link>
        {children && <button className={contentOpen ? 'down caret': 'caret'} onClick={() => setContentOpen(!contentOpen)}> </button>}
      </div>
      {contentOpen && children &&
        <div className="children">
          {children}
        </div>
      }
    </div>
  )
}

export default BurgerExpandableLink