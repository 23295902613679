import { Link } from 'gatsby'
import React from 'react'
import './DesktopMenuPanel.scss'

const DesktopMenuPanel = ({linkMap, handleHover, panelType, linkIdx}) => {

  function handleMouseEnter(idx) {
    if (handleHover)
      handleHover(idx)
  }

  const links = linkMap.map((link, idx) => {

    const linkClass = idx === linkIdx ? "link chosen" : "link";
    return (
      <div 
        className='link-div' 
        key={idx} 
        role="button" 
        tabIndex="0" 
      >
       <Link 
         className={linkClass} 
         to={link.path}
         onMouseEnter={() => handleMouseEnter(idx)}
       >
         {link.title}
       </Link>
      </div>
    )
  })

  return (
    <div id="DesktopMenuPanel" className={panelType}>
      {links}
    </div>
  )
}

export default DesktopMenuPanel