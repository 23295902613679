import { Link } from 'gatsby'
import React, { useEffect, useState } from 'react'
import ClinicFinder from './ClinicFinder'
import DesktopMenu from './DesktopMenu'
import './SectionHeaderDesktop.scss'

const SectionHeaderDesktop = () => {
  const [showFindClinic, setShowFindClinic] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    const onScroll = e => {
      handleCloseMenu();
    };
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, []);


  const rightButton = (image, callback, words) => {
    return (
      <button className="iconButton" onClick={callback}>
        <img src={image} alt="search" />
        <div className='menu-text'> {words} </div>
      </button>
    )
  }
  const modalClass = showFindClinic ? "visible": "";

  function handleCloseMenu() {
    setShowMenu(false)
  }

  return (
    <div className='SectionHeaderDesktop'>

      <div className='header-content'>
        <div className="left">
          <Link to="/">
          <img className='wink-logo' src="/assets/logo.svg" alt="logo"/>
          </Link>
            <div 
              className="menu menu-text" 
              role="button" 
              tabIndex="0" 
              onMouseEnter={() => setShowMenu(true)}
            >
                WHAT I NEED TO KNOW ABOUT...
            </div>
            {showMenu &&
              <DesktopMenu
                closeMenuHandler={handleCloseMenu}
              />
            }
            <Link to="/resources" className='menu-text'>
              RESOURCES
            </Link>
            <Link to="/about-us" className="menu-text">
              ABOUT US
            </Link>
          </div>

        <div className="right">
          <Link to="/search" className="menu-text">
            <img src={"/assets/tmp_search.svg"} alt="search" />
            <div className='menu-text'> {'SEARCH'} </div>
          </Link>
          {rightButton("/assets/tmp_map_pin.svg", () => setShowFindClinic(true), "FIND A CLINIC")}
        </div>
      </div>
      <div className={`modal ${modalClass}`}>
        <ClinicFinder modal={true} setCloseModal={() => setShowFindClinic(false)}/>
        <div 
          className="overlay" 
          role="button" 
          aria-label="Dismiss clinic finder"
          tabIndex="0" 
          onKeyPress={() => setShowFindClinic(false)}
          onClick={() => setShowFindClinic(false)}
        />
      </div>
    </div>
  )
}

export default SectionHeaderDesktop