import React from 'react'
import './SectionHeader.scss'
import SectionHeaderDesktop from './SectionHeaderDesktop'
import SectionHeaderMobile from './SectionHeaderMobile'

const SectionHeader = () => {
  return (
    <div className='SectionHeader'>
      <SectionHeaderMobile />
      <SectionHeaderDesktop />
    </div>
  )
}

export default SectionHeader