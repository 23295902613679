import React from "react"
import { Helmet } from "react-helmet"
import ClientOnly from '../clientside/ClientOnly'
import './Search.scss'

// https://stackoverflow.com/questions/60439265/google-custom-search-box-doesnt-appear-on-first-load-in-my-gatsby-project-only

const Search = () => {
  return ( 
    <div className='Search'>
      <ClientOnly>
      <Helmet>
        <script async src="https://cse.google.com/cse.js?cx=4b393de0c17004c47" ></script>
      </Helmet>
      <div className="gcse-search"></div>
      </ClientOnly>
    </div>
  )
} 
export default Search