import { Link } from 'gatsby'
import React from 'react'
import { slide as Menu } from 'react-burger-menu'
import Search from '../components/Search'
import BurgerExpandableLink from './BurgerExpandableLink'
import './BurgerMenu.scss'

const BurgerMenu = ({isOpen, setIsOpen, children}) => {
  return (
    <div className='BurgerMenu'>
      <Menu
        isOpen={isOpen}
        right
        width={ '100%' }
        onStateChange={(state) => {
          setIsOpen(state.isOpen);
        }}
       >
        <Link id="logo" to="/"><img src="/assets/logo.svg" alt="logo"/></Link>
        <Search />
        <div className="subtitle" > What I Need to Know About... </div>
        <BurgerExpandableLink to="/getting-to-know-your-body/" display="Reproductive health and biology">
          <Link className="bm-item child" to="/getting-to-know-your-body/reproductive-systems">Reproductive systems</Link>
          <Link className="bm-item child" to="/getting-to-know-your-body/menstruation">Menstruation</Link>
          <Link className="bm-item child" to="/getting-to-know-your-body/pregnancy">Pregnancy</Link>
          <Link className="bm-item child" to="/getting-to-know-your-body/hygiene">Puberty and taking care of your body</Link>
        </BurgerExpandableLink>
        <BurgerExpandableLink to="/sex-and-relationships/" display="Sex and relationships">
          <Link className="bm-item child" to="/sex-and-relationships/sexual-health">Sexual health and wellness</Link>
          <Link className="bm-item child" to="/sex-and-relationships/sexuality-and-gender-101">Sexuality 101</Link>
          <Link className="bm-item child" to="/sex-and-relationships/healthy-relationships">Healthy relationships</Link>
        </BurgerExpandableLink>
        <BurgerExpandableLink to="/birth-control/" display="Birth control" />
        <BurgerExpandableLink to="/healthy-lifestyles/" display="Mind and body wellness">
          <Link className="bm-item child" to="/healthy-lifestyles/mental-health">Mental Health</Link>
          <Link className="bm-item child" to="/healthy-lifestyles/nutrition-and-exercise">Nutrition and Exercise</Link>
          <Link className="bm-item child" to="/healthy-lifestyles/substance-use">Substance Use</Link>
        </BurgerExpandableLink>
        <BurgerExpandableLink to="/transitioning-to-adult-care/" display="Taking charge of my healthcare">
          <Link className="bm-item child" to="/transitioning-to-adult-care/readiness-assessment">My readiness for healthcare on my own</Link>
          <Link className="bm-item child" to="/transitioning-to-adult-care/health-care-rights">Healthcare rights</Link>
        </BurgerExpandableLink>
        <BurgerExpandableLink to="/preparing-for-your-visit" display="Preparing for my healthcare visit">
          <Link className="bm-item child" to="/preparing-for-your-visit/going-to-the-doctor">Why go to the doctor</Link>
          <Link className="bm-item child" to="/preparing-for-your-visit/where-could-you-go">Finding sexual and reproductive health services</Link>
          <Link className="bm-item child" to="/preparing-for-your-visit/how-to-prepare-for-your-visit">Planning for my first clinic visit</Link>
          <Link className="bm-item child" to="/preparing-for-your-visit/how-to-pay-for-your-appointment">How to pay for your appointment</Link>
        </BurgerExpandableLink>
        <BurgerExpandableLink to="/explore-a-clinic/" display="The clinic experience" />
        <BurgerExpandableLink to="/HTTTYP" display="How to talk to my provider" />
        <BurgerExpandableLink to="/resources" display="Resources" />
        <BurgerExpandableLink to="/about-us" display="About us" />
      </Menu>
    </div>
  )
}

export default BurgerMenu