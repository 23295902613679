import { Link } from 'gatsby'
import React from 'react'
import './Footer.scss'

const Footer = ({children}) => {
  const linkList = [
    { text: "Reproductive health and biology", to: "/getting-to-know-your-body"},
    { text: "Sex and relationships", to: "/sex-and-relationships"},
    { text: "Mind and body wellness", to: "/healthy-lifestyles" },
    { text: "Birth Control", to: "/birth-control"},
    { text: "Taking charge of my healthcare", to: "/transitioning-to-adult-care" },
    { text: "Preparing for my health care visit", to: "/preparing-for-your-visit"},
    { text: "Resources", to: "/resources" },
    { text: "About Us", to: "/about-us"},
  ]

  const links = linkList.map((link, idx) => {
    return <div key={idx}><Link className="link" key={link.to} to={link.to}>{link.text}</Link></div>
  })

  return (
    <div className='Footer'>
      <div className="desktop-top-row">
        <div className="logos">
          <img src="/assets/logo_big.svg" alt="WINK Logo"/>
          <div className="bottom-logos">
            <img src="/assets/UCSF_logo.svg" alt="UCSF logo"/>
            <img src="/assets/PCRHP_logo.svg" alt="PCHRP logo"/>
          </div>
        </div>
        <div className="links">
          {links.slice(0,4)}
        </div>
        <div className='links'>
          {links.slice(-4)}
        </div>
      </div>

      <div className="notices">
        <p>© 2021 The University of California, San Francisco. <a href="/about-us"> Privacy Policy </a></p>
        <p>
         This tool is provided for general informational purposes only and is not intended as, nor should it be considered a substitute for, professional medical advice. Do not use the information on this website for diagnosing or treating any medical or health condition. If you have or suspect you have a medical problem, promptly contact your professional health care provider.
        </p>
      </div>
    </div>
  )
}

export default Footer