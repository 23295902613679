import { MDXProvider } from "@mdx-js/react"
import { graphql, Link, useStaticQuery } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import React from 'react'
import './Ankle.scss'
import ClinicFinder from "./ClinicFinder"

export default function Ankle({showThirdPanel = false, showClinicTour = true}) {
  const data = useStaticQuery( graphql`
    query {
      allMdx(filter: { fileAbsolutePath: { regex: "//ankle//" } }){
        nodes {
          slug
          body
        }
      }
    }
  `)
  const clinicTour = data.allMdx.nodes.filter(node => node.slug === "ankle/clinic-tour")[0]
  const choosingBC = data.allMdx.nodes.filter(node => node.slug === "ankle/choosing-birth-control")[0]
  return (
    <div className='Ankle'>
      {showThirdPanel && <div className="section guide">
        <div className="text">
          <MDXProvider components={{}}>
            <MDXRenderer>
              {choosingBC.body}
            </MDXRenderer>
          </MDXProvider>
          <button className="ankle-button"><a href="https://clinic.mybirthcontrol.org/" target="blank">GET STARTED</a></button>

        </div>
        <div className="person">
          <img src="/assets/ankle_provider.png" alt="provider"/>
        </div>
      </div>}
      <div className="section find-clinic">
        <div className='text'>
          <ClinicFinder />
        </div>
        <div className="person">
          <img className="find-clinic-person" src="/assets/2A_repro-systems.png" alt="patient"/>
        </div>
      </div>
      {showClinicTour && <div className="section clinic-tour">
        <div className="person">
          <img className="clinic-tour-person" src="/assets/7-1B_provider-lab-coat.png" alt="provider"/>
        </div>
        <div className="text tour">
          <MDXProvider components={{}}>
            <MDXRenderer>
              {clinicTour.body}
            </MDXRenderer>
          </MDXProvider>
          <Link className="ankle-button" to="/clinic-tour">START YOUR TOUR</Link>
        </div>
      </div>}
    </div>
  )
}